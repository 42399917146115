import React, { useState, Fragment } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Spacer from "../components/Spacer";
import HeroSliderSection from "../components/HeroSliderSection";
import MisionSection from "../components/MisionSection";
import VisionSection from "../components/VisionSection";
import AboutSection from "../components/AboutSection";
import WorkProcessSection from "../components/WorkProcessSection";
import ServicesSection from "../components/ServicesSection";
import ContactSection from "../components/ContactSection";
import FooterSection from "../components/FooterSection";

//Styles

//Code
const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Spacer />
      <HeroSliderSection />
      <MisionSection />
      <VisionSection />
      <AboutSection />
      <WorkProcessSection />
      <ServicesSection />
      <ContactSection />
      <FooterSection />
    </Fragment>
  );
};

export default Home;
