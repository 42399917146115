import React, { Fragment } from "react";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { animateScroll as scroll } from "react-scroll";
import LogoSoltecAutomation from "../images/soltec1.png";

//Styles
const FooterContainer = styled.footer`
  -youbkit-touch-callout: none; /*iOS Safari*/
  -youbkit-user-select: none; /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
  -moz-user-select: none; /*Firefox*/
  -ms-user-select: none; /* IE 10+ y Edge */
  user-select: none; /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */
  background-color: #0771BD;
`;

const FooterWrap = styled.div`
  padding: 5px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: auto;
`;

const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding-top: 2px;
  }
`;

const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
  font-weight: bold;
`;

// const FooterLink = styled(LinkR)`
//   color: #fff;
//   text-decoration: none;
//   margin-bottom: 0.5rem;
//   font-size: 14px;

//   &:hover {
//     color: #01bf71;
//     transition: 0.3s ease-out;
//   }
// `;

const FooterLinkS = styled(LinkS)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    color: #00225C;
    transition: 0.3s ease-out;
  }
`;

const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: 1100px;
  margin: 1px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;



const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
  text-align: center;
`;



const FooterLogoWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

const FooterLogoLinkWrapper = styled(LinkR)`
  display: flex;
  //   align-items: center;
  //   height: 10px;
  //   width: 10px;
  //   margin:10px;
  //   font-size: 1.5rem;
`;

const FoterUpperWrap =styled.div`
  display:flex;
  align-items: center;
`;

const FooterLogoSoltec = styled.img`
  margin: 15px;
  height: 75px;
  pointer-events: none;
`;


//Code
const FooterSection = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <Fragment>
      <FooterContainer>
	<FooterWrap>
	  <FoterUpperWrap>


           <FooterLogoWrapper>
                <FooterLogoLinkWrapper to="/" onClick={toggleHome}>
                  <FooterLogoSoltec
                    src={LogoSoltecAutomation}
                    alt={"logo-soltec-automation"}
                  />
                </FooterLogoLinkWrapper>
              </FooterLogoWrapper>


  <FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>Soltec Automation</FooterLinkTitle>
                <FooterLinkS
                  href="#about"
                  to="about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Quiénes somos
                </FooterLinkS>
                <FooterLinkS
                  href="#mision"
                  to="mision"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Misión
                </FooterLinkS>
                <FooterLinkS
                  href="#vision"
                  to="vision"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Visión
		</FooterLinkS>
  <FooterLinkS
                  href="#contact"
                  to="contact"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Contáctenos
                </FooterLinkS>


              </FooterLinkItems>
              </FooterLinksWrapper>
            <FooterLinksWrapper>

            </FooterLinksWrapper>
          </FooterLinksContainer>


	  </FoterUpperWrap>
                  <SocialMedia>
            <SocialMediaWrap>

   
              <WebsiteRights>
                Soltec Automation © {new Date().getFullYear()} todos los derechos
                reservados.
              </WebsiteRights>
                         </SocialMediaWrap>
          </SocialMedia>
        </FooterWrap>
      </FooterContainer>
    </Fragment>
  );
};

export default FooterSection;
