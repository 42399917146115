import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { GoThreeBars } from "react-icons/go";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import LogoSoltecAutomation from "../images/soltec1.png";

//Styles
const Nav = styled.nav`
    -youbkit-touch-callout: none; /*iOS Safari*/
    -youbkit-user-select: none;   /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
    -moz-user-select: none;    /*Firefox*/
    -ms-user-select: none;        /* IE 10+ y Edge */
    user-select: none;            /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */

  background: ${({ scrollNav }) => (scrollNav ? "#FFFFFF" : "#FFFFFF")};
  height: 80px;
  width: 100%;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
border-bottom: 1px solid #0771BD;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #000000;
  }
`;

const BarsIcon = styled(GoThreeBars)`
  color: #0771BD;
`;

const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.li`
height: 80px;


`;

const NavLinks = styled(LinkS)`
  color:#0771BD;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;


  &.active {
    border-bottom: 3px solid #0771BD;
  }
`;


const NavLogoSoltecAutomation = styled.img`
  margin: 15px;
  pointer-events:none;
  width:auto;
`;

const NavLogoWrapper = styled.div`
  display: flex;
`;

const NavLogoLinkWrapper = styled(LinkR)`
  display: flex;
`;

//Code
const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <Fragment>
      <IconContext.Provider value={{ color: "#0771BD" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogoWrapper>
              <NavLogoLinkWrapper to="/" onClick={toggleHome}>
                <NavLogoSoltecAutomation alt="logo home soltec automation" src={LogoSoltecAutomation} />
              </NavLogoLinkWrapper>
            </NavLogoWrapper>
            <MobileIcon onClick={toggle}>
              <BarsIcon />
            </MobileIcon>
            <NavMenu>
              <NavItem>
		<NavLinks
		  href="#mision"
                  to="mision"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
		  offset={-80}

                >
                  Misión
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
		  href="#vision"
                  to="vision"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Visión
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
		  href="#about"
                  to="about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Quiénes Somos
                </NavLinks>
              </NavItem>
	                   <NavItem>
                <NavLinks
		  href="#services"
                  to="services"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Servicios
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
		  href="#contact"
                  to="contact"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Contáctenos
                </NavLinks>
              </NavItem>
            </NavMenu>
                     </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </Fragment>
  );
};

export default Navbar;
