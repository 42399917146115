import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Error404 from "../pages/Error404";


//Styles
//Code
const SoltecAutomation = () => {

  return (
    <Fragment>
      <Router>
	<Routes>
	  <Route path="/" element={<Home/>} />
	  <Route path="*" element={<Error404/>} />
	</Routes>
      </Router>
    </Fragment>
  );
};


export default SoltecAutomation;
