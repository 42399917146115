// src/component/Gallery.js
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Slider1 from "../images/Slider1.webp"
import Slider2 from "../images/Slider22.webp"
import Slider3 from "../images/Slider33.webp"



function HeroSliderSection(){




const fadeAnimationHandler = (props, state) => {
    const transitionTime = props.transitionTime + 'ms';
    const transitionTimingFunction = 'ease-in-out';

    let slideStyle = {
        position: 'absolute',
        display: 'block',
        minHeight: '100%',
        opacity: 0,
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        transitionTimingFunction: transitionTimingFunction,
        msTransitionTimingFunction: transitionTimingFunction,
        MozTransitionTimingFunction: transitionTimingFunction,
        WebkitTransitionTimingFunction: transitionTimingFunction,
        OTransitionTimingFunction: transitionTimingFunction,
    };

    if (!state.swiping) {
        slideStyle = {
            ...slideStyle,
            WebkitTransitionDuration: transitionTime,
            MozTransitionDuration: transitionTime,
            OTransitionDuration: transitionTime,
            transitionDuration: transitionTime,
            msTransitionDuration: transitionTime,
        };
    }

    return {
        slideStyle,
        selectedStyle: { ...slideStyle, opacity: 1, position: 'relative' },
        prevStyle: { ...slideStyle },
    };
};










        return (
            <div>
                
	      <Carousel showThumbs={false} autoPlay interval={5000} transitionTime={3000} infiniteLoop statusFormatter={(currentItem, total)=>{return(<p></p>)}} animationHandler={fadeAnimationHandler} swipeable={false}>
                    <div>
                        <img src={Slider3} alt="soltecautomation1" />
                        
		    </div>
                    <div>
                        <img src={Slider2} alt="soltecautomation2" />
                        
                    </div>
                    <div>
                        <img src={Slider1} alt="soltecautomation3" />
                        
                    </div>

	                </Carousel>
            </div>
        )
    };
export default HeroSliderSection


