import { Fragment } from "react";
import styled from "styled-components";



//Styles


const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;


//Code
const Spacer = () => {
 
  return (
    <Fragment>
          <NavbarContainer>
          </NavbarContainer>
    </Fragment>
  );
};

export default Spacer;
