import React, { Fragment } from "react";
import SoltecAutomation from "./components/SoltecAutomation";
import { GlobalStyles } from "./components/GlobalStyles.style";

//Code
function App() {
  return (
    <Fragment>
      <GlobalStyles />
        <SoltecAutomation />
    </Fragment>
  );
}

export default App;
