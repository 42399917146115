import React, { Fragment } from "react";
import styled from "styled-components";
import Residencial from "../images/Residencial2.svg";

import Empresarial from "../images/Empresarial.svg";
import Agro from "../images/Agro2.svg";

//Styles

const ServicesContainer = styled.div`
  -youbkit-touch-callout: none; /*iOS Safari*/
  -youbkit-user-select: none; /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
  -moz-user-select: none; /*Firefox*/
  -ms-user-select: none; /* IE 10+ y Edge */
  user-select: none; /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

const InfoServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};

  // @media screen and (max-width: 768px) {
  //  height: 1100px;
  // }

  // @media screen and (max-width: 480px) {
  //  height: 1300px;
  // }
`;

const CasesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-start;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

const CasesItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
`;

const CaseTitle = styled.div`
  line-height: 2.5rem;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
  //Se pone margin botton 20 ya que los vectores estan a raz para igualar a los de forma de trabajo que tienen un pequeño margen superior de aprox 10px
  hyphens: none;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

const CaseImg = styled.img`
  height: 170px;
  width: 170px;
  object-fit: fill;
  margin-bottom: 10px;
  pointer-events: none;
`;

const CaseP = styled.p`
  font-size: 18px;
  line-height: 24px;
  padding: 0 15px;
  height: 400px;
  color: black;
  font-size: 1rem;
  text-align: center;
  hyphens: none;

  @media screen and (max-width: 1000px) {
    height: auto;
  }
`;

const ServicesH1 = styled.h1`
  padding: 24px;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
  line-height: 2rem;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

//Code
const ServicesSection = () => {
  return (
    <Fragment>
      <ServicesContainer lightBg={true} id="services">
        <InfoServicesContainer lightBg={true}>
          <ServicesH1 lightText={false}>Nuestros Servicios</ServicesH1>
          <CasesWrapper>
            <CasesItem>
              <CaseTitle>Instalación Residencial</CaseTitle>
              <CaseImg src={Residencial} alt={"instalacion-residencial"} />
              <CaseP>
                Para quienes quieren tener un ahorro mes a mes en su factura de
                energía o sencillamente para quienes desean aportar un grano de
                arena con el medio ambiente reduciendo 1 Kg de CO2 por cada 1,3
                KWh de energía generada.
              </CaseP>
            </CasesItem>
            <CasesItem>
              <CaseTitle>Instalación Empresarial</CaseTitle>
              <CaseImg src={Empresarial} alt={"instalacion-empresarial"} />
              <CaseP>
                Hoy por hoy las empresas, oficinas y todo aquello que genere
                consumo en el día son las más beneficiadas con la instalación de
                energía solar fotovoltaica, ya que sencillamente toda la
                producción del proyecto se consume directamente en las labores
                cotidianas de dichas compañías.
              </CaseP>
            </CasesItem>
            <CasesItem>
              <CaseTitle>Instalación Agro</CaseTitle>
              <CaseImg src={Agro} alt={"instalacion-agro"} />
              <CaseP>
                La mayoría de las fincas productoras tienen alta demanda
                energética para sus labores cotidianas y en muchos casos
                deficiente suministro energético o cortes de energía frecuentes,
                generando pérdidas incalculables, diseñamos y construimos los
                proyectos de acuerdo a cada necesidad para quienes requieren
                plantas solares de apoyo o totalmente aisladas para una demanda
                completa de energía.
              </CaseP>
            </CasesItem>
          </CasesWrapper>
        </InfoServicesContainer>
      </ServicesContainer>
    </Fragment>
  );
};

export default ServicesSection;
