import React, { Fragment } from "react";
import styled from "styled-components";
import Consulta from "../images/Consulta2.svg";

import Propuesta from "../images/Propuesta2.svg";
import Instalacion from "../images/Instalacion2.svg";
import Inspeccion from "../images/Inspeccion2.svg";

import CasoExito1 from "../images/CasoExito11.webp";
import CasoExito2 from "../images/CasoExito22.webp";
import CasoExito3 from "../images/CasoExito333.webp";

//Styles

const ServicesContainer = styled.div`
  -youbkit-touch-callout: none; /*iOS Safari*/
  -youbkit-user-select: none; /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
  -moz-user-select: none; /*Firefox*/
  -ms-user-select: none; /* IE 10+ y Edge */
  user-select: none; /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};

  @media screen and (max-width: 768px) {
    padding-top: 80px;
  }
`;

const InfoServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};

  // @media screen and (max-width: 768px) {
  //  height: 1100px;
  // }

  // @media screen and (max-width: 480px) {
  //  height: 1300px;
  // }
`;

const CasesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

const CasesItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
`;

const CaseTitle = styled.div`
  font-size: 2.5rem;
  hyphens: none;
  text-align: center;
  margin-bottom: 10px;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

const CaseImg = styled.img`
  height: 170px;
  width: 170px;
  object-fit: fill;
  margin-bottom: 10px;
  pointer-events: none;
`;

const CaseP = styled.p`
  font-size: 18px;
  line-height: 24px;
  padding: 0 15px;
  color: black;
  font-size: 1rem;
  text-align: center;
  hyphens: none;
`;

const CaseH1 = styled.h1`
  font-size: 2.5rem;
  padding: 24px;
  margin-bottom: 64px;
  text-align: center;
  line-height: 2rem;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

const ServicesWrapper = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

const ServicesCard = styled.div`
  background: #0771bd;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-width: 290px;
  max-width: 1000px;
  height: auto;
  align-items: center;
  border-radius: 10px;
  //  max-height: 340px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ServicesIcon = styled.img`
  height: 300px;
  width: 300px;
  margin-bottom: 10px;
  pointer-events: none;
`;

const ServicesTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ServicesH1 = styled.h1`
  padding: 24px;
  line-height: 2rem;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

const ServicesH2 = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 10px;
  text-align: center;
`;

const ServicesP = styled.p`
  display: block;
  color: #ffffff;
  font-size: 1rem;
  text-align: center;
  hyphens: none;
`;

//Code
const WorkProcessSection = () => {
  return (
    <Fragment>
      <ServicesContainer lightBg={true} id="work-process">
        <InfoServicesContainer lightBg={true}>
          <ServicesH1 lightText={false}>Nuestro proceso de Trabajo</ServicesH1>
          <CasesWrapper>
            <CasesItem>
              <CaseTitle>Consulta</CaseTitle>
              <CaseImg src={Consulta} alt={"consulta"} />
              <CaseP>
                Tomamos el primer contacto con el cliente para determinar sus
                necesidades y requerimientos, de esta forma podemos brindar la
                mejor asesoría integral y hacer una propuesta acorde.
              </CaseP>
            </CasesItem>
            <CasesItem>
              <CaseTitle>Propuesta</CaseTitle>
              <CaseImg src={Propuesta} alt={"propuesta"} />
              <CaseP>
                Buscamos ofrecer el mejor costo beneficio según los
                requerimientos expresados por el cliente, brindando calidad,
                garantía y beneficio a largo plazo.
              </CaseP>
            </CasesItem>
            <CasesItem>
              <CaseTitle>Instalación</CaseTitle>
              <CaseImg src={Instalacion} alt={"instalacion"} />
              <CaseP>
                Siempre buscamos una sinergia entre los requerimientos tanto del
                cliente como del sistema ya que en muchos casos las malas
                experiencias con la energía solar están dadas por una mala
                ubicación de las placas o muchos aspectos que se pasan por alto,
                buscamos satisfacer las necesidades del cliente utilizando
                personal altamente calificado
              </CaseP>
            </CasesItem>
            <CasesItem>
              <CaseTitle>Inspección</CaseTitle>
              <CaseImg src={Inspeccion} alt={"instalacion"} />
              <CaseP>
                Después de crear un vínculo comercial, buscamos mantener dicho
                vínculo con un acompañamiento postventa para seguir asesorando y
                hacer un correcto seguimiento de los sistemas instalados,
                garantizando que los mismos cumplan y superen las expectativas
                del cliente.
              </CaseP>
            </CasesItem>
          </CasesWrapper>
          <CaseH1 lightText={false}>Nuestros casos de éxito</CaseH1>
          <ServicesWrapper>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={CasoExito1} alt={"caso-exito-1"} />
              <ServicesTextWrap>
                <ServicesH2>Finca El Remanso</ServicesH2>
                <ServicesP>
                  Ubicada en el municipio de Salento, Quindío, la finca El
                  Remanso contaba con un servicio intermitente de electricidad,
                  Soltec Automation Instaló un proyecto completamente autónomo,
                  donde el propietario pasó de pagar mensualmente un aproximado
                  de $400.000, a contar con un sistema libre de mantenimiento
                  que proporciona 23Kw/H día de energía, lo que abastece a todo
                  el predio de energía sin tener interrupciones, brindando un
                  suministro constante por los siguientes 25 años.
                </ServicesP>
              </ServicesTextWrap>
            </ServicesCard>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={CasoExito2} alt={"caso-exito-2"} />
              <ServicesTextWrap>
                <ServicesH2>Condominio Las Margaritas</ServicesH2>
                <ServicesP>
                  Ubicado en la ciudad de Pereira, Risaralda, el propietario
                  requería de un sistema de energía para su circuito cerrado de
                  seguridad en su casa campestre, en la zona se presentan
                  constantes cortes de energía impidiendo el monitoreo
                  permanente de la propiedad, Soltec Automation se adaptó a esta
                  necesidad y diseño un proyecto a medida para satisfacer su
                  requerimiento, instalando un kit autónomo libre de
                  mantenimiento que suministra 5.4Kw/H día de energía, suple la
                  iluminación de la casa, el circuito cerrado de televisión 24/7
                  y un TV de 40” con su respectivo DVR.
                </ServicesP>
              </ServicesTextWrap>
            </ServicesCard>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={CasoExito3} alt={"caso-exito-3"} />
              <ServicesTextWrap>
                <ServicesH2>Finca Buenos Aires</ServicesH2>
                <ServicesP>
                  Ubicada en el municipio Andes, Antioquia, el cliente está
                  realizando la construcción de su casa en una zona de difícil
                  acceso donde no tiene disponibilidad de conexión a la red
                  eléctrica, pagando altos costos de combustible
                  para alimentar los generadores eléctricos que suplían la
                  maquinaria de la construcción, cansado de esta situación se
                  puso en contacto con Soltec Automation y diseñamos un proyecto
                  a la medida de sus necesidades, instalando un sistema de
                  paneles solares fotovoltaicos que genera una producción de
                  9400Kw/H día de energía. Lo que permitirá que finalice la
                  construcción y alimente el consumo normal del predio.
                </ServicesP>
              </ServicesTextWrap>
            </ServicesCard>
          </ServicesWrapper>
        </InfoServicesContainer>
      </ServicesContainer>
    </Fragment>
  );
};

export default WorkProcessSection;
