import React, { Fragment } from "react";
import styled from "styled-components";
import {
  IoPhonePortrait,
  IoMailOpenOutline,
  IoLogoInstagram,
  IoLogoWhatsapp,
  IoLogoFacebook,
} from "react-icons/io5";
import ContactImg from "../images/ContactoSoltec2.webp";

//Styles
const ContactFormContainer = styled.div`
  -youbkit-touch-callout: none; /*iOS Safari*/
  -youbkit-user-select: none; /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
  -moz-user-select: none; /*Firefox*/
  -ms-user-select: none; /* IE 10+ y Edge */
  user-select: none; /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #ffffff;

  @media screen and (max-width: 1366px) {
    height: auto;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    padding-top:80px ;
  }

  @media screen and (max-width: 480px) {
    height: auto;
  }
`;

const ContactFormH1Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 24px;
`;

const ContactFormH1 = styled.h1`
  font-size: 2.5rem;
  color: #000000;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

const ContactFormPWrapper = styled.div`
  max-width: 1000px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
  padding-left: 24px;
  padding-right: 24px;
`;

const ContactFormP = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  padding: 0 15px;
  padding-bottom: 24px;
  hyphens: none;
`;

const ContactFormWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  align-items: center;
  justify-content: center;
`;

const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1 col1' 'col2 col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

const ContactFormMobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const MobileIcon = styled.div`
  color: #ffffff;
  font-size: 50px;
`;

const MobileLink = styled.a`
  color: #484848;
  font-size: 100px;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 24px;
  padding-right: 10px;

  &:hover {
    color: #0771bd;
    transition: 0.3s ease-out;
  }
`;

const ContactFormWhatsAppWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const WhatsAppIcon = styled.div`
  color: #fff;
  font-size: 50px;
`;

const WhatsAppLink = styled.a`
  color: #484848;
  font-size: 100px;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 24px;
  padding-right: 10px;

  &:hover {
    color: #0771bd;
    transition: 0.3s ease-out;
  }
`;

const ContactFormEmailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const EmailIcon = styled.div`
  color: #fff;
  font-size: 50px;
`;

const EmailLink = styled.a`
  color: #484848;
  font-size: 100px;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 18px;
  padding-right: 10px;
  // se baja el font size por que genera overflow que no se puede cortar y hace aparecer scroll bar horizontal que mueve todo
  &:hover {
    color: #0771bd;
    transition: 0.3s ease-out;
  }
`;

const ContactFormInstagramWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const InstagramIcon = styled.div`
  color: #fff;
  font-size: 50px;
`;

const InstagramLink = styled.a`
  color: #484848;
  font-size: 100px;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 18px;
  padding-right: 10px;
  // se baja el font size por que genera overflow que no se puede cortar y hace aparecer scroll bar horizontal que mueve todo
  &:hover {
    color: #0771bd;
    transition: 0.3s ease-out;
  }
`;

const ContactFormFacebookWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const FacebookIcon = styled.div`
  color: #fff;
  font-size: 50px;
`;

const FacebookLink = styled.a`
  color: #484848;
  font-size: 100px;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 18px;
  padding-right: 10px;
  // se baja el font size por que genera overflow que no se puede cortar y hace aparecer scroll bar horizontal que mueve todo
  &:hover {
    color: #0771bd;
    transition: 0.3s ease-out;
  }
`;

const ImgWrap = styled.div`
  max-width: 555px;
  height: 50%;
`;

const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  pointer-events: none;
  object-fit: contain;
`;

//Code

const ContactForm = () => {
  return (
    <Fragment>
      <ContactFormContainer lightBg={false} id="contact">
        <ContactFormH1Wrapper>
          <ContactFormH1>Contáctenos</ContactFormH1>
        </ContactFormH1Wrapper>
        <ContactFormPWrapper>
          <ContactFormP>
            En Soltec Automation estamos siempre disponibles para usted,
            contactenos a través de cualquiera de los medios que ponemos a su
            disposición
          </ContactFormP>
        </ContactFormPWrapper>
        <ContactFormWrapper>
          <InfoRow imgStart={true}>
            <Column1>
              <ContactFormMobileWrapper>
                <MobileIcon>
                  <MobileLink href="tel:+573113411108">
                    <IoPhonePortrait size={50} />
                  </MobileLink>
                </MobileIcon>
                <MobileLink href="tel:+573113411108">
                  (+57) 311 3411108
                </MobileLink>
              </ContactFormMobileWrapper>
              <ContactFormWhatsAppWrapper>
                <WhatsAppIcon>
                  <WhatsAppLink
                    href="https://wa.me/573113411108"
                    target="_blank"
                  >
                    <IoLogoWhatsapp size={50} />
                  </WhatsAppLink>
                </WhatsAppIcon>
                <WhatsAppLink href="https://wa.me/573113411108" target="_blank">
                  (+57) 311 3411108
                </WhatsAppLink>
              </ContactFormWhatsAppWrapper>
              <ContactFormEmailWrapper>
                <EmailIcon>
                  <EmailLink href="mailto:gerencia@soltecautomation.com">
                    <IoMailOpenOutline size={50} />
                  </EmailLink>
                </EmailIcon>
                <EmailLink href="mailto:gerencia@soltecautomation.com">
                  gerencia@soltecautomation.com
                </EmailLink>
              </ContactFormEmailWrapper>
              <ContactFormInstagramWrapper>
                <InstagramIcon>
                  <InstagramLink href="https://www.instagram.com/soltecautomation/">
                    <IoLogoInstagram size={50} />
                  </InstagramLink>
                </InstagramIcon>
                <InstagramLink href="https://www.instagram.com/soltecautomation/">
                  @soltecautomation
                </InstagramLink>
              </ContactFormInstagramWrapper>
              <ContactFormFacebookWrapper>
                <FacebookIcon>
                  <FacebookLink href="https://m.facebook.com/Soltecautomationcol">
                    <IoLogoFacebook size={50} />
                  </FacebookLink>
                </FacebookIcon>
                <FacebookLink href="https://m.facebook.com/Soltecautomationcol">
                  @soltecautomation
                </FacebookLink>
              </ContactFormFacebookWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={ContactImg} alt="img-contact" />
                {/*<Img src={require('../images/Contact.jpg').default} alt='img-contact'/>*/}
              </ImgWrap>
            </Column2>
          </InfoRow>
        </ContactFormWrapper>
      </ContactFormContainer>
    </Fragment>
  );
};

export default ContactForm;
