import { Fragment } from "react";
import styled from "styled-components";
import { Link as LinkS } from "react-scroll";
import { FaTimes } from "react-icons/fa";
import React from "react";

//Styles
const SidebarContainer = styled.aside`
    -youbkit-touch-callout: none; /*iOS Safari*/
    -youbkit-user-select: none;   /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
    -moz-user-select: none;    /*Firefox*/
    -ms-user-select: none;        /* IE 10+ y Edge */
    user-select: none;            /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */

  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

const CloseIcon = styled(FaTimes)`
  color: #fff;
`;

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

const SidebarWrapper = styled.div`
  color: #fff;
`;

const SidebarMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;
  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6, 60px);
  }
`;

const SidebarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: #0771BD;
    transition: 0.2s ease-in-out;
  }
`;


//Code
const Sidebar = ({ isOpen, toggle }) => {
  return (
    <Fragment>
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
          <CloseIcon />
        </Icon>
        <SidebarWrapper>
          <SidebarMenu>
            <SidebarLink href="#mision" to="mision" onClick={toggle}>
              Misión
            </SidebarLink>

            <SidebarLink  href="#vision" to="vision" onClick={toggle}>
              Visión
            </SidebarLink>
            <SidebarLink href="#about" to="about" onClick={toggle}>
              Quiénes Somos
            </SidebarLink>
                   <SidebarLink href="#services" to="services" onClick={toggle} offset={-79}>
              Servicios
            </SidebarLink>
            <SidebarLink href="#contact" to="contact" onClick={toggle}>
              Contáctenos
            </SidebarLink>
          </SidebarMenu>
               </SidebarWrapper>
      </SidebarContainer>
    </Fragment>
  );
};

export default Sidebar;
